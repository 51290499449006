gcbAppControllers.controller('ReserveEventController', function ($scope, $routeParams, PageTitleFactory, NavbarActiveItemFactory, ArticlesFactory, TextboxesFactory) {
    PageTitleFactory.setTitle('Rezervišite događaj');

    if ($routeParams.tab) {
        setTimeout(function () {
            $('.page-tabs a[data-target="#page-tab-'+$routeParams.tab+'"]').tab('show');
        }, 500);
    }

    ArticlesFactory.reserveEvent().then(function(article) {
        $scope.article = article.data;
    });
    
    var init = function () {
        mod_datepicker.init();
        mod_slideControl.init();
    };
    setTimeout(init, 500);
});