function TextboxesFactoryFunction($http) {
    var factory = {};

    factory.specialPackages = function () {
        return $http.get("/public/frontend/mock/textboxes.php?article=special_packages");
    };
    
    factory.responsibleGambling = function () {
        return $http.get("/public/frontend/mock/textboxes.php?article=responsible_gambling");
    };
    
    factory.aboutUs = function () {
        return $http.get("/public/frontend/mock/textboxes.php?article=about_us");
    };
    
    factory.dpm = function () {
        return $http.get("/public/frontend/mock/textboxes.php?article=dpm");
    };

    return factory;

}