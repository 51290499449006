gcbAppControllers.controller('ResponsibleGamblingController', function ($scope, PageTitleFactory, NavbarActiveItemFactory, ArticlesFactory, TextboxesFactory) {
    PageTitleFactory.setTitle('Odgovorno kockanje');
    NavbarActiveItemFactory.setActive(5);

    ArticlesFactory.responsibleGambling().then(function(article) {
        $scope.article = article.data;
    });
    
    TextboxesFactory.responsibleGambling().then(function(textbox) {
        $scope.textboxes = textbox.data;
    });
});