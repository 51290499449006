Date.prototype.germanDate = function() {
    var month = this.getMonth() + 1;
    var day = this.getDate();
    return [!day[1] && '0' + '' + day, !month[1] && '0' + '' + month, this.getFullYear()].join('.');
};

String.prototype.ucfirst = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

Array.prototype.remove = function() {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
};