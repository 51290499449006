gcbAppControllers.controller('GalleryController', function ($scope, $routeParams, PageTitleFactory, NavbarActiveItemFactory, GalleriesFactory) {
    PageTitleFactory.setTitle('Galerija');
    NavbarActiveItemFactory.setActive(4);
    
    if ($routeParams.id) {
        GalleriesFactory.gallery($routeParams.id).then(function(response) {
            $scope.gallery = response.data;
        });
    } else {
        GalleriesFactory.galleries().then(function(response) {
            $scope.galleries = response.data;
        });
    }
});