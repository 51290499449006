modules.define('mod_slideControl', function ($) {
    return {
        init: function () {
            $('.input-group.slide-control-group').on('change', '.slide-control', function () {
                var $slider = $(this),
                    $input = $slider.next('.form-control'),
                    value = $slider.val();
                $input.val(value);
            });
            
            $('.input-group.slide-control-group').on('change mouseup keyup keydown focus focusout', '.form-control:not(.slide-control)', function () {
                var $input = $(this),
                    $slider = $input.prev('.form-control'),
                    slider = $slider.get(0),
                    value = $input.val();
                slider.value = parseInt(value);
            });
        }
    }
}, jQuery);