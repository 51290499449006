function GalleriesFactoryFunction($http) {
    var factory = {};

    factory.gallery = function (id) {
        return $http.get("/public/frontend/mock/galleries.php?gallery=" + id);
    };
    
    factory.galleries = function () {
        return $http.get("/public/frontend/mock/galleries.php");
    };

    return factory;
}