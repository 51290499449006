gcbAppControllers.controller('HomeController', function ($scope, PageTitleFactory, HomepageBannersFactory) {
    PageTitleFactory.setTitle('Naslovna');

    setTimeout(function () {
        if (getHashParams()['/#igre'] != undefined) {
            var $games = $('#bgl-1');
            if ($games.length > 0) {
                $('html, body').animate({
                    scrollTop: $games.offset().top
                }, 500);
            }
        }
    }, 800);

    angular.extend($scope, {
        homepageBanners: HomepageBannersFactory.fetch()
    });
});