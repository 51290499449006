function ArticlesFactoryFunction ($http) {
    var factory = {};
    
    factory.liveGames = function () {
        return $http.get("/public/frontend/mock/articles.php?article=live_games");
    };
    
    factory.slot = function () {
        return $http.get("/public/frontend/mock/articles.php?article=slot");
    };
    
    factory.aboutUs = function () {
        return $http.get("/public/frontend/mock/articles.php?article=about_us");
    };
    
    factory.texasHoldem = function () {
        return $http.get("/public/frontend/mock/articles.php?article=texas_holdem");
    };
    
    factory.jackpots = function () {
        return $http.get("/public/frontend/mock/articles.php?article=jackpots");
    };
    
    factory.specialPackages = function () {
        return $http.get("/public/frontend/mock/articles.php?article=special_packages");
    };
    
    factory.becomeMember = function () {
        return $http.get("/public/frontend/mock/articles.php?article=become_member");
    };
    
    factory.events = function () {
        return $http.get("/public/frontend/mock/articles.php?article=events");
    };
    
    factory.responsibleGambling = function () {
        return $http.get("/public/frontend/mock/articles.php?article=responsible_gambling");
    };
    
    factory.reserveEvent = function () {
        return $http.get("/public/frontend/mock/articles.php?article=reserve_event");
    };
    
    factory.pokerFestivals = function () {
        return $http.get("/public/frontend/mock/articles.php?article=poker_festivals");
    };
    
    factory.dpm = function () {
        return $http.get("/public/frontend/mock/articles.php?article=dpm");
    };
    
    factory.promotions = function () {
        return $http.get("/public/frontend/mock/articles.php?article=promotions");
    };
    
    return factory;
}