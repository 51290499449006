gcbAppControllers.controller('DineWineController', function ($scope, PageTitleFactory, MultilayerArticlesFactory, GalleriesFactory) {
    PageTitleFactory.setTitle('Dine & Wine');

    MultilayerArticlesFactory.dineWine().then(function(article) {
        $scope.article = article.data;
    });
    
    GalleriesFactory.gallery(1).then(function(response) {
        $scope.gallery = response.data;
    });
});