function PagesFactoryFunction($http) {
    var factory = {};

    factory.dpm = function () {
        return $http.get("/public/frontend/mock/pages.php?page=dpm");
    };
    
    factory.prevDpm = function () {
        return $http.get("/public/frontend/mock/pages.php?page=prev_dpm");
    };

    return factory;
}