modules.define('mod_datepicker', function ($) {
    return {
        init: function () {
            $('.input-group.date').datepicker({
                format: 'dd.mm.yyyy'
            });

            $('.input-daterange').datepicker({
                format: "dd.mm.yyyy"
            });
        }
    }
}, jQuery);