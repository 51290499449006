function HomepageBannersFactoryFunction ($http) {
    var factory = {},
        partners = [];
    
    factory.fetch = function () {
        partners = [];
        var responsePromise = $http.post("/public/frontend/mock/homepage-banners.php");
        responsePromise.success(function (data, status, headers, config) {
            data.forEach(function (u) {
                partners.push(u);
            });
        });
        responsePromise.error(function (data, status, headers, config) {
            alert("Operation failed! If bug persists, please contact the administrators.");
        });

        return partners;
    };
    
    return factory;
}