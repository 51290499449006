modules.define('mod_tabLinks', function ($) {
    return {
        init: function () {
            $(document).on('click', 'a', function (e) {
                e.preventDefault();
                var $link = $(this),
                        href = $link.attr('href');
                if (href.indexOf('#page-tab-') === 0) {
                    $('.page-tabs a[data-target="'+href+'"]').tab('show');
                }
            });
        }
    }
}, jQuery);