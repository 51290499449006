gcbAppControllers.controller('SpecialPackagesController', function ($scope, PageTitleFactory, NavbarActiveItemFactory, ArticlesFactory, TextboxesFactory) {
    PageTitleFactory.setTitle('Specijalni paketi');
    NavbarActiveItemFactory.setActive(3);

    ArticlesFactory.specialPackages().then(function(article) {
        $scope.article = article.data;
    });
    
    TextboxesFactory.specialPackages().then(function(textbox) {
        $scope.textboxes = textbox.data;
    });
});