gcbAppControllers.controller('AboutUsController', function ($scope, $routeParams, PageTitleFactory, NavbarActiveItemFactory, ArticlesFactory, TextboxesFactory) {
    PageTitleFactory.setTitle('O nama');
    NavbarActiveItemFactory.setActive(1);
    
    if ($routeParams.tab) {
        setTimeout(function () {
            $('.page-tabs a[data-target="#page-tab-'+$routeParams.tab+'"]').tab('show');
        }, 500);
    }

    ArticlesFactory.aboutUs().then(function(article) {
        $scope.article = article.data;
    });
    
    TextboxesFactory.aboutUs().then(function(textbox) {
        $scope.textboxes = textbox.data;
    });
});