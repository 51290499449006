modules.define('mod_scrollToTop', function ($) {
    return {
        init: function () {
            
            window.scrollTo(0, 0);

            $(document).on('click', '.js_scroll-to-top', function () {
                $('html, body').animate({
                    scrollTop: 0
                }, 500);
            });
        }
    }
}, jQuery);

function getHashParams() {
    var hashParams = {};
    var e,
            a = /\+/g,
            r = /([^&;=]+)=?([^&;]*)/g,
            d = function (s) {
                return decodeURIComponent(s.replace(a, " "));
            },
            q = window.location.hash.substring(1);

    while (e = r.exec(q))
        hashParams[d(e[1])] = d(e[2]);

    return hashParams;
}

var navMain = $("#main-navbar-collapse");
navMain.on("click", "a:not([data-toggle])", null, function () {
    navMain.collapse('hide');
});